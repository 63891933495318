import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container">
        <section className="section">
            <div className="title">PAGE NOT FOUND</div>
            <p className="pres">Please go back to the <a href="/">main page.</a></p>
        </section>
    </div>
  </Layout>
)

export default NotFoundPage
